import React, {useState, Fragment} from "react";
import {Link} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import {getFormData, setFormData} from "../../components/manager/form";
import {Container, Col, Row} from "react-bootstrap";
import {useQuery} from "@apollo/client";

import Panel from "./panel";
import {CIS_LINK, NON_INTERNET_PRODUCT_TYPES_TO_SHOW} from "../../utils/constant";
import {getProductsQuery, getProductsResult} from "../../components/logic/products";
import {getBuildingCIS} from "../../components/logic/address";
import {getPlanSpeedLabel, getPlanImage} from "./plan";
import AddonsDisplay from "./addons-display";

const Index = (props) => {
  const {buildingCode} = getFormData();
  const query = useQuery(getBuildingCIS, {
    variables: {
      code: buildingCode,
    },
  });

  if (query.loading) {
    return <Fragment />;
  }

  const cisLink = query?.data?.classMethods?.Building?.getBuildingCIS;
  return (
    <Review cisLink={cisLink?.length ? cisLink : CIS_LINK} {...props} />
  );
};


const Review = ({cisLink}) => {
  const {formatMessage} = useIntl();
  const [date, setDate] = useState();
  const data = getFormData();

  const {selectedProduct, voucherCode, addons = []} = data;

  const result = useQuery(getProductsQuery, {
    variables: {
      buildingCode: data.buildingCode,
      voucherCode: data.voucherCode,
      showHidden: true,
      selectedProductIds: [],
    },
  });

  if (result.loading) {
    return (<React.Fragment/>);
  }

  const products = getProductsResult(result);


  if (!data.selectedProduct) {
    return (<div />);
  }

  const {period} = selectedProduct;

  const setupProducts = products.filter((pp) =>
    pp.required && pp.id !== selectedProduct.id && !pp.onlyNewConnection
  );
  let newConnectionProducts = [];
  if (data?.address?.isNewConnection) {
    newConnectionProducts = products.filter((pp) =>
      pp.id !== selectedProduct.id && pp.onlyNewConnection
    );

  }

  let connectionFee = newConnectionProducts.reduce((c, ncp) => c + parseFloat(ncp.value), 0);
  let setupFee = setupProducts.reduce((c, ncp) => c + parseFloat(ncp.value), 0);
  const total = selectedProduct.value + connectionFee + setupFee + addons.reduce((o, p) => p.value + o, 0);
  const perMonth = selectedProduct.value + addons.filter((p) => NON_INTERNET_PRODUCT_TYPES_TO_SHOW.indexOf(p.type) > -1).reduce((o, p) => p.value + o, 0);

  const handleOnAddProduct = (product, selectEvents) => {
    let newAddons = [...addons, product, ...selectEvents];
    newAddons = newAddons.filter((value, index) => newAddons.findIndex((na) => na.id === value.id) === index);

    const hardware = newAddons.filter((p) => p.type === "hardware").length > 0;
    setFormData({addons: newAddons, hardware});
    setDate(new Date()); //force page rerender
  };
  const handleOnRemoveProduct = (product, selectEvents) => {
    const newAddons = addons.filter((a) => a.id !== product.id);
    const hardware = newAddons.filter((p) => p.type === "hardware").length > 0;
    setFormData({addons: newAddons, hardware});
    setDate(new Date()); //force page rerender
  };

  return (
    <Panel className="review">
      <Container fluid>
        <Row>
          <Col xs={12}>
            <div className="title">
              <FormattedMessage id="register.review.title" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={10} className="mx-auto">
            <div className="product-display">
              <Container fluid>
                <Row>
                  <Col className="mx-auto" xs="auto">
                    <div className="pd-title">
                      {`${selectedProduct.name} Plan`}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="mx-auto" xs="auto">
                    <div className="image">
                      {getPlanImage(selectedProduct.name)}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="mx-auto" xs="auto">
                    <div className="price text-center">
                      <div style={{
                        textDecoration: (selectedProduct.originalValue) ? "line-through" : undefined,
                      }}>{`$${selectedProduct.originalValue ? selectedProduct.originalValue : selectedProduct.value}/month`}</div>
                      {(selectedProduct || {}).voucherName && <div className="product-voucher">
                        {`$${selectedProduct.value} ${selectedProduct.voucherName}`}
                      </div>}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="mx-auto" xs="auto">
                    <div className="description">
                      {selectedProduct.description}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="mx-auto" xs="auto">
                    <div className="evening-speed">
                      {`Typical Evening Speed: ${getPlanSpeedLabel(selectedProduct.name)} Mbps`}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
        <Row className="addons-row">
          <Col xs={12} lg={10} className="mx-auto">
            <AddonsDisplay
              voucherCode={voucherCode}
              onAddProduct={handleOnAddProduct}
              selectedProductIds={[]}
              onRemoveProduct={handleOnRemoveProduct}
              addons={addons}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="auto" className="mx-auto">
            <h3 className="final-header">{"Summary"}</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={10} className="mx-auto">
            <div className="review-costs">
              <Container fluid>
                {connectionFee > 0 && (<Row className="totals justify-content-center align-items-center">
                  <Col xs={12}>
                    <div className="header">
                      {"New Developments Charge (Once off)"}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="value">
                      {`$${connectionFee}`}
                    </div>
                  </Col>
                </Row>)}
                {total !== perMonth && (<Row className="totals justify-content-center align-items-center">
                  <Col xs={12}>
                    <div className="header">
                      {"Initial Payment (Including First Month)"}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="value">
                      {`$${total}`}
                    </div>
                  </Col>
                </Row>)}
                <Row className="totals justify-content-center align-items-center">
                  <Col xs={12}>
                    <div className="header">
                      {"Total Per Month"}
                    </div>
                  </Col>
                  <Col xs={12} >
                    <div className="value">
                      {`$${perMonth}/Month`}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="review-footer">
        <Row>
          <Col xs={12}>
            <div className="buttons">
              <Link className="vw-button orange confirm-btn" to="/user/name">
                <FormattedMessage id={"buttons.confirm1"} />
              </Link>
            </div>
          </Col>
        </Row>
        {selectedProduct.voucherUses > 0 ? (<Row>
          <Col xs={12}>
            <div className="change">
              <div>
                {`Voucher applies for ${selectedProduct.voucherUses} ${period.split(" ")[1].toLowerCase()}`}
              </div>
            </div>
          </Col>
        </Row>) : undefined}
        <Row>
          <Col xs={12}>
            <div className="change">
              <div>
                <Link to={"/plan"}>
                  <FormattedMessage id="register.review.back" />
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="minimum">
              <FormattedMessage
                id="register.review.total"
                values={{
                  value: `${total}`,
                  renewal: formatMessage({id: `register.products.renewal.${period.split(" ")[1].toLowerCase()}`}),
                }}
              />
            </div>
            <div className="info-summary">
              <a href={cisLink || "javascript:void(0);"} target={cisLink ? "_blank" : ""}>
                <FormattedMessage id="register.review.critical" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </Panel>
  );
};

export default Index;
